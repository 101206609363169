<template>
  <div>
    <b-card class="mb-5" no-body>
      <b-card-header class="p-1" header-tag="header" role="tab">
        <b-button v-b-toggle.accordion-2 block class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon"
                  variant="link">
          {{ $t('advanced_filter') }}
        </b-button>
      </b-card-header>
      <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel" visible>
        <b-card-body>
          <div class="m-form m-form--fit m--margin-bottom-20">
            <div class="row mb-5">
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="item_id">{{ $t('product') }}</label>
                <div class="d-flex search-item-group">
                  <select v-model="f_by" class="custom-select" name="" style="width: 100px;">
                    <option selected value="name">{{ $t('items.name') }}</option>
                    <option value="sku_code">{{ $t('items.sku_code') }}</option>
                  </select>
                  <multiselect id="item_id"
                               v-model="items_ids"
                               :internal-search="false"
                               :multiple="true"
                               :options="items"
                               :label="f_by?f_by:'sku_code'"
                               :placeholder="$t('product')"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               :taggable="false"
                               track-by="id"
                               @input="getIds('items')"
                               @search-change="getItems(f_by, $event)">
                  </multiselect>
                </div>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('sales_by_client_report.invoice_type') }}</label>
                <select id="invoice_type" v-model="filters.document" class="custom-select" name="">
                  <option :value="null">{{ $t('all') }}</option>
                  <option v-for="row in invoice_type_list" :key="row.key" :disabled="row.disabled" :value="row.key">
                    {{ row.name }}
                  </option>
                </select>
              </div>


              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="customer">{{ $t('customer') }}</label>
                <multiselect id="customer" v-model="customers_ids"
                             :multiple="true"
                             :options="customers"
                             :placeholder="$t('customer')"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             :taggable="false"
                             label="name"
                             track-by="id"
                             @input="getIds('customers')"
                             @search-change="getCustomers($event)">
                </multiselect>
              </div>

              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('sales_by_client_report.branch') }}</label>
                <multiselect v-model="branches_ids"
                             :multiple="true"
                             :options="branches"
                             :placeholder="$t('sales_by_client_report.branch')"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             :taggable="false"
                             label="name"
                             track-by="id"
                             @input="getIds('branches')">
                </multiselect>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('sales_by_client_report.employee') }}</label>
                <multiselect v-model="user"
                             :placeholder="$t('sales_by_client_report.employee')"
                             label="name"
                             track-by="id"
                             :options="users"
                             :multiple="false"
                             @input="getIds('users')"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             @search-change="getUsers($event)">
                </multiselect>
              </div>

              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="from_date">{{ $t('inventory_transaction_report.from_date') }}</label>
                <input id="from_date" v-model="filters.from_date" class="form-control" type="date">
              </div>

              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="to_date">{{ $t('inventory_transaction_report.to_date') }}</label>
                <input id="to_date" v-model="filters.to_date" class="form-control" type="date">
              </div>
              <div class="col-md-6 mt-2 mb-2 pt-10">
                <div>
                  <b-button-group>
                    <b-button @click="changeIntervalType('current_day')" :variant="filters.period=='current_day'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.current_date') }}</b-button>
                    <b-button @click="changeIntervalType('current_month')" :variant="filters.period=='current_month'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.current_month') }}</b-button>
                    <b-button @click="changeIntervalType('current_year')" :variant="filters.period=='current_year'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.current_year') }}</b-button>
                    <b-button @click="changeIntervalType('financial_year')" :variant="filters.period=='financial_year'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.financial_year') }}</b-button>
                    <!--                    <b-button  @click="changeFinancialYear" :variant="filters.financial_year==1? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.financial_year')}}</b-button>-->
                  </b-button-group>

                </div>
                <div class="mt-5">
                  <b-button-group>
                    <b-button @click="changeIntervalType('yesterday')" :variant="filters.period=='yesterday'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.yesterday') }}</b-button>
                    <b-button @click="changeIntervalType('last_month')" :variant="filters.period=='last_month'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.last_month') }}</b-button>
                    <b-button @click="changeIntervalType('last_year')" :variant="filters.period=='last_year'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.last_year') }}</b-button>
                  </b-button-group>

                </div>
              </div>

              <div class="form-group d-inline-flex col-md-4 mt-10">
                <b-button class="mt-auto mr-2" variant="primary" @click="doFilter"> {{ $t('display_report') }}
                </b-button>
                <b-button class="mt-auto mr-2" variant="danger" @click="resetFilter"><i
                    class="fas fa-trash-restore"></i> {{ $t('reset_search') }}
                </b-button>

              </div>
            </div>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>

    <!--begin::customer-->
    <div class="card card-custom mt-5">

      <div class="card-body">

        <div class="col-md-12 mb-5">
          <div class="m-form m-form--fit m--margin-bottom-20">
            <b-tabs content-class="mt-3">
              <b-tab :title="$t('summery')" active>
                <div class="row">
                  <div id="summaryTableTitle" class="col-12 mt-10">
                    <h4 class="text-center">{{ $t('MENU.sales_report') }}</h4>
                    <h5 class="text-center"><span v-if="filters.from_date">{{ $t('from') }}
                      {{ filters.from_date }}</span> <span v-if="filters.to_date">{{ $t('to') }}
                      {{ filters.to_date }}</span>
                    </h5>
                  </div>
                  <div class="col-12 mt-10 d-flex justify-content-end">
                    <b-dropdown :text="$t('export')" class="mr-2 ml-2" right>
                      <button class="dropdown-item" @click="convertTableToExcelReport('summaryTable')">
                        <span><i class="fa fa-file-excel"></i> {{ $t('excel') }}</span>
                      </button>
                      <button class="dropdown-item" @click="printData('summaryTable', 'print')">
                        <span><i class="fa fa-print"></i> <span> {{ $t('print') }}</span></span>
                      </button>
                      <button class="dropdown-item" @click="printPDFData('summaryTable', 'pdf')">
                        <span><i class="fa fa-file-pdf"></i> <span> {{ $t('export_pdf') }}</span></span>
                      </button>
                      <button class="dropdown-item" @click="getAllDataForExport('sectionForExportSummary')">
                        <span><i class="fa fa-file-excel"></i> <span> {{ $t('all_data') }}</span></span>
                      </button>
                    </b-dropdown>
                  </div>
                  <div class="col-12 mt-10">
                    <div class="table-responsive">
                      <table class="table table-bordered text-center" id="summaryTable">
                        <thead>
                        <tr>
                          <th>{{ $t('sales_for_sales_report.' + periodLabel) }}</th>
                          <th>{{ $t('sales_for_sales_report.qty') }}</th>
                          <th>{{ $t('sales_for_sales_report.discount') }}</th>
                          <th>{{ $t('sales_for_sales_report.total') }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(row, index) in data_more_details" :key="index">
                          <td>{{ row.label }}</td>
                          <td>{{ row.total.qty }}</td>
                          <td>{{ row.total.discount_val }}</td>
                          <td>{{ row.total.subtotal_price }}</td>
                        </tr>
                        </tbody>
                        <tfoot>
                        <tr v-if="data_more_details_total">
                          <td>{{ $t('total') }}</td>
                          <th>{{ data_more_details_total.qty }}</th>
                          <th>{{ data_more_details_total.discount_val }}</th>
                          <th>{{ data_more_details_total.subtotal_price }}</th>
                        </tr>
                        </tfoot>
                      </table>
                    </div>

                  </div>
                </div>
              </b-tab>

              <b-tab :title="$t('details')">
                <div class="row">
                  <div id="summaryTableTitle" class="col-12 mt-10">
                    <h4 class="text-center">{{ $t('MENU.sales_report') }}</h4>
                    <h5 class="text-center"><span v-if="filters.from_date">{{ $t('from') }}
                      {{ filters.from_date }}</span> <span v-if="filters.to_date">{{ $t('to') }}
                      {{ filters.to_date }}</span>
                    </h5>
                  </div>
                  <div class="col-12 mt-10 d-flex justify-content-end">
                    <b-dropdown :text="$t('export')" class="mr-2 ml-2" right>
                      <button class="dropdown-item" @click="convertTableToExcelReport('summaryTableDetails')">
                        <span><i class="fa fa-file-excel"></i> {{ $t('excel') }}</span>
                      </button>
                      <button class="dropdown-item" @click="printData('summaryTableDetails', 'print')">
                        <span><i class="fa fa-print"></i> <span> {{ $t('print') }}</span></span>
                      </button>
                      <button class="dropdown-item" @click="printPDFData('summaryTableDetails', 'pdf')">
                        <span><i class="fa fa-file-pdf"></i> <span> {{ $t('export_pdf') }}</span></span>
                      </button>
                      <button class="dropdown-item" @click="getAllDataForExport('sectionForExportDetails')">
                        <span><i class="fa fa-file-excel"></i> {{ $t('all_data') }}</span>
                      </button>
                    </b-dropdown>
                  </div>
                  <div class="col-12 mt-10">
                    <div class="table-responsive">
                      <table class="table table-bordered" id="summaryTableDetails">
                        <thead>
                        <tr id="myRow">
                          <th>{{ $t('sales_for_sales_report.item_id') }}</th>
                          <th>{{ $t('sales_for_sales_report.item_name') }}</th>
                          <th>{{ $t('sales_for_sales_report.item_code') }}</th>
                          <th>{{ $t('sales_for_sales_report.date') }}</th>
                          <th>{{ $t('sales_for_sales_report.employee') }}</th>
                          <th>{{ $t('sales_for_sales_report.invoice') }}</th>
                          <th>{{ $t('sales_for_sales_report.customer') }}</th>
                          <th>{{ $t('sales_for_sales_report.unit_price') }}</th>
                          <th>{{ $t('sales_for_sales_report.qty') }}</th>
                          <th>{{ $t('sales_for_sales_report.discount') }}</th>
                          <th>{{ $t('sales_for_sales_report.total') }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="(row, index) in data_more_details">
                          <tr>
                            <td colspan="11">{{ row.label }}</td>
                          </tr>
                          <tr v-for="(row2, index2) in row.details" :key="'css'+getRandom()+index2">
                            <td>{{ row2.id }}</td>
                            <td>{{ row2.product_name }}</td>
                            <td>{{ row2.code }}</td>
                            <td>{{ row2.invoice_date }}</td>
                            <td>{{ row2.added_by }}</td>
                            <td>{{ row2.invoice_code }}</td>
                            <td>{{ row2.customer_name }}</td>
                            <td>{{ row2.price_per_unit }}</td>
                            <td>{{ row2.qty }}</td>
                            <td>{{ row2.discount_val }}</td>
                            <td>{{ row2.subtotal_price }}</td>
                          </tr>
                          <tr :key="'d'+index">
                            <td colspan="8">{{ $t('sales_for_sales_report.total') }}</td>
                            <td>{{ row.total.qty }}</td>
                            <td>{{ row.total.discount_val }}</td>
                            <td>{{ row.total.subtotal_price }}</td>
                          </tr>
                        </template>

                        </tbody>
                        <!-- <tr>
                            <td colspan="6"><b>{{$t('total')}}</b></td>
                            <th>{{details_total_sum}}</th>
                        </tr> -->
                        <tfoot>
                        <tr v-if="data_more_details_total">
                          <td colspan="8">{{ $t('total') }}</td>
                          <th>{{ data_more_details_total.qty }}</th>
                          <th>{{ data_more_details_total.discount_val }}</th>
                          <th>{{ data_more_details_total.subtotal_price }}</th>
                        </tr>
                        </tfoot>
                      </table>
                    </div>

                  </div>
                </div>
              </b-tab>

              <template #tabs-end>
                <li class="nav-item  d-flex my-auto">
                  <select name="" id="" v-model="filters.filter_by" class="custom-select border-0 pt-0 pb-0 h-auto shadow-none">
                    <option :value="null" :disabled="true">{{ $t('select_filter') }}</option>
                    <option value="daily">{{ $t('daily') }}</option>
                    <option value="weekly">{{ $t('weekly') }}</option>
                    <option value="monthly">{{ $t('monthly') }}</option>
                    <option value="yearly">{{ $t('yearly') }}</option>
                    <option value="customer">{{ $t('customer') }}</option>
                    <option value="employee">{{ $t('employee') }}</option>
                    <option value="item">{{ $t('item') }}</option>
                  </select>

                </li>
              </template>
              <!-- <b-tab> -->

              <!-- </b-tab> -->

            </b-tabs>
          </div>
          <div v-if="page" class="col-12 text-center">
            <button class="btn btn-warning" @click="loadMore">
              <p class="mb-0">
                <b-icon font-scale="1" icon="arrow-counterclockwise"></b-icon>
                {{ $t('load_more') }}
              </p>
            </button>
          </div>
        </div>
        <!-- End Filter -->

      </div>
    </div>
    <div class="export-container">
      <div class="section-for-export">
        <table class="table table-bordered text-center" id="sectionForExportSummary">
          <thead>
          <tr>
            <th>{{ $t('sales_for_sales_report.' + periodLabel) }}</th>
            <th>{{ $t('sales_for_sales_report.qty') }}</th>
            <th>{{ $t('sales_for_sales_report.discount') }}</th>
            <th>{{ $t('sales_for_sales_report.total') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(row, index) in dataList" :key="index">
            <td>{{ row.label }}</td>
            <td>{{ row.total.qty }}</td>
            <td>{{ row.total.discount_val }}</td>
            <td>{{ row.total.subtotal_price }}</td>
          </tr>
          </tbody>
          <tfoot>
          <tr v-if="dataTotalList">
            <td>{{ $t('total') }}</td>
            <th>{{ dataTotalList.qty }}</th>
            <th>{{ dataTotalList.discount_val }}</th>
            <th>{{ dataTotalList.subtotal_price }}</th>
          </tr>
          </tfoot>
        </table>


        <table class="table table-bordered" id="sectionForExportDetails">
          <thead>
          <tr id="myRow">
            <th>{{ $t('sales_for_sales_report.item_id') }}</th>
            <th>{{ $t('sales_for_sales_report.item_name') }}</th>
            <th>{{ $t('sales_for_sales_report.item_code') }}</th>
            <th>{{ $t('sales_for_sales_report.date') }}</th>
            <th>{{ $t('sales_for_sales_report.employee') }}</th>
            <th>{{ $t('sales_for_sales_report.invoice') }}</th>
            <th>{{ $t('sales_for_sales_report.customer') }}</th>
            <th>{{ $t('sales_for_sales_report.unit_price') }}</th>
            <th>{{ $t('sales_for_sales_report.qty') }}</th>
            <th>{{ $t('sales_for_sales_report.discount') }}</th>
            <th>{{ $t('sales_for_sales_report.total') }}</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(row, index) in dataList">
            <tr>
              <td colspan="11">{{ row.label }}</td>
            </tr>
            <tr v-for="(row2, index2) in row.details" :key="'cddd'+getRandom()+index+index2">
              <td>{{ row2.id }}</td>
              <td>{{ row2.product_name }}</td>
              <td>{{ row2.code }}</td>
              <td>{{ row2.invoice_date }}</td>
              <td>{{ row2.added_by }}</td>
              <td>{{ row2.invoice_code }}</td>
              <td>{{ row2.customer_name }}</td>
              <td>{{ row2.price_per_unit }}</td>
              <td>{{ row2.qty }}</td>
              <td>{{ row2.discount_val }}</td>
              <td>{{ row2.subtotal_price }}</td>
            </tr>
            <tr :key="'d'+index">
              <td colspan="8">{{ $t('daily_brief_report_invoices.total') }}</td>
              <td>{{ row.total.qty }}</td>
              <td>{{ row.total.discount_val }}</td>
              <td>{{ row.total.subtotal_price }}</td>
            </tr>
          </template>

          </tbody>
          <tfoot>
          <tr v-if="dataTotalList">
            <td colspan="8">{{ $t('total') }}</td>
            <th>{{ dataTotalList.qty }}</th>
            <th>{{ dataTotalList.discount_val }}</th>
            <th>{{ dataTotalList.subtotal_price }}</th>
          </tr>
          </tfoot>
        </table>

      </div>
    </div>
    <!--end::customer-->

    <div class="export-container">
      <div class="section-for-export" id="sectionForExportHeader">
        <report-main-header :print-type="'SalesForSalesReport'"></report-main-header>
      </div>
    </div>

    <div class="selector-export" id="selectorExport"></div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import TableToExcel from "@linways/table-to-excel";
import exportPDFInnerAction from "@/core/plugins/export-jspdf-inner-action";
import ReportMainHeader from "@/view/content/printing-heads/ReportMainHeader.vue";
import cssStypeForPrintReport from "@/core/config/mix/cssStypeForPrintReport";

export default {
  name: "index-sales-for-sales-report",
  components: {ReportMainHeader},

  data() {
    return {
      mainRoute: '/reports/sales_reports/more-details',
      mainRouteDependency: 'base/dependency',
      items: [],
      data: [],
      dataTotal: [],
      customers: [],
      users: [],
      currencies: [],

      data_more_details: [],
      data_more_details_total: [],

      dataList: [],
      dataTotalList: [],

      filters: {
        items_ids: null,
        customers_ids: null,
        branches_ids: null,
        user_id: null,
        document: null,
        from_date: null,
        to_date: null,
        filter_by: 'daily',

        period: null,
        range: null,
        f_time_period: 0,
      },
      items_ids: [],
      customers_ids: [],
      branches_ids: [],
      user: null,
      branches: [],


      invoice_type_list: [
        {key: 'SalesInvoice', name: this.$t('invoices_types_trans.sales_invoice'), disabled: false},
        // {key: 'general_sales_invoice', name: this.$t('invoices_types_trans.general_sales_invoice'), disabled: false},
        // {key: 'pos', name: this.$t('invoices_types_trans.pos'), disabled: false},
        {key: 'SalesInvoiceRefund', name: this.$t('invoices_types_trans.sales_refund'), disabled: false},
        {key: 'SalesCredit', name: this.$t('invoices_types_trans.sales_credit'), disabled: false},
        // {key: 'sales_debit', name: this.$t('invoices_types_trans.sales_debit'), disabled: false},
        // {key: 'general_sales_refund', name: this.$t('invoices_types_trans.general_sales_refund'), disabled: false},
        // {key: 'general_sales_credit', name: this.$t('invoices_types_trans.general_sales_credit'), disabled: false},
        // {key: 'general_sales_debit', name: this.$t('invoices_types_trans.general_sales_debit'), disabled: false},
        // {key: 'sales_payment', name: this.$t('invoices_types_trans.sales_payment'), disabled: false},
        // {key: 'expenses_income_transaction', name: this.$t('invoices_types_trans.expenses_income_transaction'), disabled: false},
      ],
      page: 0,
      f_by: 'name',
      periodLabel: 'day',
    }
  },

  watch: {
    "filters.filter_by": function (val) {
      if (val)
        this.doFilter();
      switch (val) {
        case 'daily':
          this.periodLabel = 'day';
          break;
        case 'weekly':
          this.periodLabel = 'week';
          break;
        case 'monthly':
          this.periodLabel = 'month';
          break;
        case 'yearly':
          this.periodLabel = 'year';
          break;
        case 'customer':
          this.periodLabel = 'customer';
          break;
        case 'employee':
          this.periodLabel = 'employee';
          break;
        case 'item':
          this.periodLabel = 'item';
          break;
        default:
          this.periodLabel = '';
          break;
      }
    },
  },
  methods: {
    ...cssStypeForPrintReport,
    setItems(val) {
      if (val && val.length > 0) {
        this.filters.item_id = val.map((item) => item.id);
      } else {
        this.filters.item_id = null;
      }
    },
    convertTableToExcelReport(table_id = "summaryTableDetails") {
      this.setReportLog('excel', 'sales report');

      TableToExcel.convert(document.getElementById(table_id), {
        name: this.$t('MENU.sales_report') + '.xlsx',
        sheet: {
          name: 'Sheet 1'
        }
      });
    },

    getRandom() {
      return Math.floor(Math.random() * 100000000);
    },


    selectFilter() {
      this.page = 1;
      this.getData();
    },

    loadMore() {
      this.page = this.page ? (this.page + 1) : 1;
      this.getData();
    },

    doFilter() {
      this.page = 1;
      this.getData();
    },
    getIds(related_with) {
      switch (related_with) {
        case 'customers':
          this.filters.customers_ids = this.customers_ids.map((row) => row.id);
          break;
        case 'items':
          this.filters.items_ids = this.items_ids.map((row) => row.id);
          break;
        case 'branches':
          this.filters.branches_ids = this.branches_ids.map((row) => row.id);
          break;
        case 'users':
          this.filters.user_id = this.user.id;
          break;
      }
    },
    changeIntervalType(action_type, range = null) {
      if (this.filters.period == action_type) {
        this.filters.period = null;
        this.filters.f_time_period = 0;
      } else {
        this.filters.period = action_type;
        this.filters.f_time_period = 1;
      }

    },
    resetFilter() {
      this.filters.items_ids = [];
      this.filters.customers_ids = [];
      this.filters.branches_ids = [];
      this.filters.user_id = null;
      this.filters.document = null;
      this.filters.from_date = null;
      this.filters.to_date = null;
      this.filters.period = null;
      this.filters.range = null;
      this.filters.f_time_period = 0;
      this.items_ids = [];
      this.customers_ids = [];
      this.branches_ids = [];
      this.user = null;
      this.getData();

    },
    dataReport() {
      this.setReportLog('excel', 'sales report');
      return this.data;
    },


    getData() {
      ApiService.get(this.mainRoute, {
        params: {
          ...this.filters,
          page: (this.page ? this.page : 1),
        }
      }).then((response) => {

        this.data_more_details = response.data.data.data;
        this.data_more_details_total = response.data.data.total;
      }).then().catch((error) => {
        this.$errorAlert(error)
      });
    },


    getAllDataForExport(tableId) {
      ApiService.get(this.mainRoute, {params: {...this.filters, limit: 999999999999999}}).then((response) => {
        this.dataList = response.data.data.data;
        this.dataTotalList = response.data.data.total;
        setTimeout(() => {
          // if (_export == 'pdf' || _export == 'print')
          //   this.printData(tableId, _export);
            this.convertTableToExcelReport(tableId);

        }, 100)
      });
    },
    updateValue(value) {
      this.filters.category_id = value
    },
    loadOptions() {
    },

    getItems(f_by = null, filter = null) {
      if (filter && filter.length > 3 && f_by)
        ApiService.get(this.mainRouteDependency + "/items", {params: {[f_by]: filter}}).then((response) => {
          this.items = response.data.data;
        });
    },


    getCustomers(filter) {
      if (filter && filter.length >= 3)
        ApiService.get(this.mainRouteDependency + "/customers", {params: {filter: filter}}).then((response) => {
          this.customers = response.data.data;
        });
    },
    getUsers(filter) {
      if (filter && filter.length >= 3)
        ApiService.get(this.mainRouteDependency + "/users", {params: {filter: filter}}).then((response) => {
          this.users = response.data.data;
        });
    },
    getBranches() {
      ApiService.get(this.mainRouteDependency + "/branches").then((response) => {
        this.branches = response.data.data;
      });
    },

    printPDFData(tableId, type) {
      this.setReportLog('pdf', 'sales report');
      // let divToPrint = document.getElementById(tableId);
      // let divTitleToPrint = document.getElementById(tableId + 'Title');
      // let newWin = window.open("");
      // newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>" + divTitleToPrint.outerHTML + divToPrint.outerHTML);
      // setTimeout(() => {
      //     newWin.print();
      //     newWin.close();
      // }, 100)
      exportPDFInnerAction(tableId, type, this.$t('MENU.sales_report'), this.$t('MENU.sales_report'));

    },
    printData(tableId) {
      this.setReportLog('pdf', 'sales report');
      let divToPrint = document.getElementById(tableId);
      let divTitleToPrint = document.getElementById('sectionForExportHeader');
      let newWin = window.open("");
      newWin.document.write('<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">');
      newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>");
      newWin.document.write('<style>');
      newWin.document.write(this.getCssStyleForPrintReport());
      newWin.document.write('</style>');
      newWin.document.write(divTitleToPrint.outerHTML + divToPrint.outerHTML);
      setTimeout(() => {
        newWin.print();
        // newWin.close();
      }, 100)
    },
    setReportLog(key, description) {
      ApiService.post("reports/system_activity_log/log_type", {
        key: key,
        description: description,
      });
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: this.$t("MENU.sales_reports"),
      route: '/reports/sales-reports-links'
    }, {title: this.$t("MENU.sales_report")}]);

    this.getBranches();

  },
};
</script>
<style>
.html2pdf__overlay {
  opacity: 0 !important;
  z-index: -1000;

}

.selector-export {
  opacity: 0 !important;
  z-index: -1000;
  width: 0.0001px;
  height: 0.0001px;
  overflow: auto;
}
</style>
